import axios from "axios";
import Pushy from "pushy-sdk-web";
import storage from "../util/storage";

// const url = "http://localhost:8083/";
const url = "https://c4-admin.retrogularexpression.com/"

const pushyAPPId = "620a50477cbf7cf450888570";

const instance = axios.create({
  baseURL: url,
  timeout: 1000 * 60,
  headers: { "Content-Type": "application/json" },
});

const login = async (account) => {
  try {
    let result = await instance.post("/login", { account });
    let { device_token } = result.data;
    if (device_token != null && device_token != "") {
      storage.setDeviceToken(device_token);
      return { success: true };
    } else {
      let pushyDeviceToken = await Pushy.register({ appId: pushyAPPId });
      if (pushyDeviceToken != null && pushyDeviceToken != "") {
        storage.setDeviceToken(pushyDeviceToken);
        let registDeviceTokenResult = await instance.post("/register", {
          account,
          device_token: pushyDeviceToken,
        });
        return { success: true };
      } else {
        return { success: false };
      }
    }
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const getStatus = async () => {
  try {
    let result = await instance.post("/getStatus", {});
    let { isInuse } = result.data;
    return { success: true, isInuse };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const updateStatus = async (isInuse) => {
  try {
    await instance.post("/updateStatus", { inuse: isInuse });
    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

const bellRing = async () => {
  try {
    await instance.post("/bell", {});
    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
}

const getBellLog = async () => {
  try {
    let result = await instance.post("/getBellLog", {});
    let { log } = result.data
    return { success: true, data: log };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
}

const clearBellLog = async () => {
  try {
    await instance.post("/clearBellLog", {});
    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
}

export default {
  getStatus,
  updateStatus,
  login,
  bellRing,
  getBellLog,
  clearBellLog
};
