import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import API from "../API/API";
import "./Admin.css";

const Admin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const setIsInuse = async () => {
    setIsLoading(true)
    await API.updateStatus(true);
    setIsLoading(false)
  };

  const setNotInUse = async () => {
    setIsLoading(true)
    await API.updateStatus(false);
    setIsLoading(false)
  };

  return (
    <div style={{ marginTop: "30px", marginLeft: "30px", marginRight: "30px" }}>
      <center>
        {isLoading ? (
          <div style={{ marginTop: "20vh" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <button
              className="button"
              style={{ minWidth: "100%", color: "#000" }}
              onClick={() => setNotInUse()}
            >
              <p
                style={{ fontSize: "24px", margin: "5px" }}
              >
                Normal
              </p>
            </button>
            <button
              className="button"
              style={{
                minWidth: "100%",
                backgroundColor: "red",
                color: "#fff",
              }}
              onClick={() => setIsInuse()}
            >
              <p
                style={{ fontSize: "24px", margin: "5px" }}
              >
                Darkroom In Use
              </p>
            </button>
            <button
              className="button"
              style={{
                minWidth: "100%",
                backgroundColor: "green",
                color: "#fff",
              }}
              onClick={() => navigate('/bellLog')}
            >
              <p
                style={{ fontSize: "24px", margin: "5px" }}
              >
                Door bell log
              </p>
            </button>
          </>
        )}
      </center>
    </div>
  );
};

export default Admin;
