import React from "react";
import Pushy from "pushy-sdk-web";
import Normal from "../Components/Main/Normal";
import DarkroomInUse from "../Components/Main/DarkroomInUse";
import API from "../API/API";
import { Navigate } from "react-router-dom";
import storage from "../util/storage";
import "./Main.css";

class Main extends React.Component {
  constructor() {
    super();
    this.pushyListener = null;
  }

  state = {
    isInuse: false,
    device_token: storage.getDeviceToken(),
  };

  messageListener = () => {
    Pushy.setNotificationListener((data) => {
      console.log("123")
      let { isInuse } = data;
      if (typeof isInuse == "boolean") {
        this.setState((prev) => {
          let temp = prev;
          temp.isInuse = isInuse;
          return temp;
        });
      }
    });
  };

  componentDidMount = async () => {
    await this.getStatus();
    this.getDeviceToken();
    if (this.pushyListener == null) {
      this.pushyListener = this.messageListener;
      this.pushyListener();
    }
  };

  componentWillUnmount = () => {
    this.pushyListener = null
  }

  getDeviceToken = () => {
    let temp = this.state;
    temp.device_token = storage.getDeviceToken();
    this.setState(temp);
  };

  getStatus = async () => {
    let result = await API.getStatus();
    console.log(result)
    if (result.success) {
      let { isInuse } = result;
      let temp = this.state;
      temp.isInuse = isInuse;
      this.setState(temp);
    }
  };

  render() {
    if (this.state.device_token != null && this.state.device_token != "") {
      if (this.state.isInuse) {
        return <DarkroomInUse />;
      } else {
        return <Normal />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  }
}

export default Main;
