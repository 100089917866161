import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import storage from "../util/storage";
import API from "../API/API";
import "./Login.css";

class Login extends React.Component {
  state = {
    account: "",
    loading: false,
    isLoginSuccess: false,
    message: "",
  };

  componentDidMount() {
    console.log(storage.getDeviceToken());
  }

  onAccountChangeHandler = (event) => {
    let { value } = event.target;
    let temp = this.state;
    temp.account = value;
    this.setState(temp);
  };

  login = async () => {
    if(this.state.account != "" && this.state.account != null){
      let temp = this.state;
      temp.loading = true
      this.setState(temp);
      let result = await API.login(this.state.account);
      if(result.success){
        temp.loading = false
        temp.isLoginSuccess = true;
        this.setState(temp);
        // console.log(temp)
      }else{
        temp.loading = false
        temp.isLoginSuccess = false;
        temp.message = "Login error"
        this.setState(temp);
      }
    }
  };

  render() {
    if(this.state.isLoginSuccess){
      return <Navigate to="/" />;
    }else{
      return (
        <div className="loginBg">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Account</Form.Label>
              <Form.Control
                type="password"
                placeholder="Account"
                autoComplete="off"
                value={this.state.account}
                onChange={this.onAccountChangeHandler}
                disable={this.state.loading}
              />
            </Form.Group>
            <br />
            <br />
            <center>
              {this.state.loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <Button variant="primary" style={{ minWidth: "100%" }} onClick={this.login}>
                  Submit
                </Button>
              )}
              <p style={{marginTop: "30px"}}>
                {this.state.message}
              </p>
            </center>
          </Form>
        </div>
      );
    }
  }
}

export default Login;
