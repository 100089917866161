import React from "react";
import "./App.css";
import Main from "./Pages/Main";
import Admin from "./Pages/Admin";
import Login from "./Pages/Login";
import BellLog from "./Pages/BellLog";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/bellLog" element={<BellLog />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </Router>
  );
}

export default App;
