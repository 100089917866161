import { useEffect } from 'react'
import "./Normal.css";
import BellIcon from "../../image/notification_1.png";
import bellRing from "../../sound/doorbell.mp3"
import API from "../../API/API";

let bell = null

const Normal = () => {

  useEffect(() => {
    bell = new Audio(bellRing);
  },[])
 
  const bellPlay = () => {
    bell.pause();
    bell.currentTime = 0;
    bell.play();
    API.bellRing()
  };

  return (
    <div
      className="App"
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div style={{ paddingTop: "15vh" }}>
        <font className="page-title">RETROGULAR EXPRESSION</font>
      </div>
      <img
        className="bellIcon"
        src={BellIcon}
        style={{ maxWidth: "100px", maxHeight: "100px" }}
        onClick={bellPlay}
      />
    </div>
  );
};

export default Normal;
