import { useEffect } from 'react'
import "./DarkroomInUse.css";
import BellIcon from "../../image/notification_2.png";
import bellRing from "../../sound/doorbell.mp3";
import API from "../../API/API";

let bell = null

const DarkroomInUse = () => {
  
  useEffect(() => {
    bell = new Audio(bellRing);
  },[])
 
  const bellPlay = () => {
    bell.pause();
    bell.currentTime = 0;
    bell.play();
    API.bellRing()
  };

  return (
    <div
      className="App"
      style={{ backgroundColor: "#000", minHeight: "100vh" }}
    >
      <div className="container" style={{ paddingTop: "5vh" }}>
        <div className="operation" style={{ maxWidth: "80vh" }}>
          <font>手術中</font>
        </div>
      </div>
      <div>
        <font className="page-title" style={{ color: "red" }}>
          <b>
            DARKROOM <br />
            IN USE
          </b>
        </font>
      </div>
      <img
        className="bellIcon"
        src={BellIcon}
        style={{ maxWidth: "100px", maxHeight: "100px" }}
        onClick={bellPlay}
      />
    </div>
  );
};

export default DarkroomInUse;
