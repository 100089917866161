import React from "react";
import { Spinner, Table } from "react-bootstrap";
import API from "../API/API";

class BellLog extends React.Component {
  constructor() {
    super();
  }

  state = {
    data: [],
    loading: false,
  };

  componentDidMount = async () => {
    await this.getBellLog();
  };

  clearBellLog = async () => {
    this.setState((prev) => {
      let temp = prev;
      temp.loading = true;
      return temp;
    });
    await API.clearBellLog();
    await this.getBellLog();
    this.setState((prev) => {
      let temp = prev;
      temp.loading = false;
      return temp;
    });
  };

  getBellLog = async () => {
    this.setState((prev) => {
      let temp = prev;
      temp.loading = false;
      return temp;
    });
    let result = await API.getBellLog();
    if (result.success) {
        console.log(result.data)
      this.setState((prev) => {
        let temp = prev;
        temp.loading = false;
        temp.data = result.data;
        return temp;
      });
    } else {
      this.setState((prev) => {
        let temp = prev;
        temp.loading = false;
        return temp;
      });
    }
  };

  render() {
    let body = null;

    if (this.state.loading) {
      body = (
        <div style={{ marginTop: "20vh" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    } else {
      body = (
        <>
          <button
            className="button"
            style={{
              minWidth: "100%",
              backgroundColor: "red",
              color: "#fff",
            }}
            onClick={this.clearBellLog}
          >
            <p style={{ fontSize: "24px", margin: "5px" }}>
              Clear Door bell log
            </p>
          </button>
          <br />
          <br />
          <br />
          <Table>
            <thead>
              <tr>
                <th>Door Bell Press Datetime</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((x) => {
                return (
                  <tr key={Math.random()}>
                    <td>{x.timestamp}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      );
    }
    return (
      <div
        style={{ marginTop: "30px", marginLeft: "30px", marginRight: "30px" }}
      >
        <center>{body}</center>
      </div>
    );
  }
}

export default BellLog;
