const getDeviceToken = () => {
  return window.localStorage.getItem("device_token");
};

const setDeviceToken = (device_token) => {
  window.localStorage.setItem("device_token", device_token);
};

const deleteDeviceToken = () => {
  window.localStorage.removeItem("device_token");
};

export default { getDeviceToken, setDeviceToken, deleteDeviceToken };
